import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IntroPage from './pages/IntroPage'; // 导入 IntroPage 组件
import DownloadPage from './pages/DownloadPage'; // 导入 DownloadPage 组件
import iconImage from './assets/images/icon.png'; // 导入图标
import { Helmet } from 'react-helmet';

function App() {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState('download');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // 添加语言检测逻辑
  useEffect(() => {
    const browserLang = navigator.language;

    if (browserLang.startsWith('zh')) {
      // 特殊处理中文区域
      if (browserLang.includes('TW') || browserLang.includes('HK')) {
        i18n.changeLanguage('zh-TW');
      } else {
        i18n.changeLanguage('zh');
      }
    } else {
      // 检查是否支持该语言
      const supportedLanguages = ['en', 'ja', 'ko', 'fr'];
      const primaryLang = browserLang.split('-')[0];
      const isSupported = supportedLanguages.includes(primaryLang);
      i18n.changeLanguage(isSupported ? primaryLang : 'en');
    }
  }, [i18n]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <title>{i18n.language.startsWith('zh') ? '贴密 - 匿名社交 | 树洞倾诉 | 漂流瓶 | 话题交友' : t('appName') + ' - ' + t('slogan')}</title>
        <meta name="description" content={t('description')} />
        <meta name="keywords" content="贴密,树洞,漂流瓶,匿名社交,倾诉,社交软件,聊天软件,ComboMe,社交app,匿名聊天,话题卡片,群聊,交友软件,聊天交友,树洞倾诉" />
        <link rel="canonical" href="https://cooktalk.top" />

        {/* 添加更多搜索引擎相关标签 */}
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="baiduspider" content="index, follow" />

        {/* 添加移动端优化标签 */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="format-detection" content="telephone=no" />

        {/* 添加更多社交媒体标签 */}
        <meta property="og:site_name" content="贴密" />
        <meta property="og:locale" content={i18n.language} />
      </Helmet>
      <div className="min-h-screen flex flex-col bg-gradient-to-b from-yellow-50 via-yellow-100 to-yellow-200 text-gray-800 font-sans">
        <nav className="bg-yellow-300 shadow-md">
          <div className="container mx-auto px-4 py-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img src={iconImage} alt="ComboMe" className="h-8 w-8 mr-2 rounded-full object-cover" />
                <h1 className="text-2xl font-bold text-gray-800">ComboMe</h1>
              </div>
              <div className="hidden md:flex space-x-4">
                <button
                  onClick={() => setActiveTab('download')}
                  className={`px-4 py-2 rounded-full ${activeTab === 'download' ? 'bg-yellow-500 text-white' : 'text-gray-800'}`}
                >
                  {t('appDownload')}
                </button>
                <button
                  onClick={() => setActiveTab('intro')}
                  className={`px-4 py-2 rounded-full ${activeTab === 'intro' ? 'bg-yellow-500 text-white' : 'text-gray-800'}`}
                >
                  {t('productIntro')}
                </button>
              </div>
              <div className="md:hidden">
                <button onClick={toggleMenu} className="text-gray-800 focus:outline-none">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
                  </svg>
                </button>
              </div>
            </div>
            {isMenuOpen && (
              <div className="mt-4 md:hidden">
                <button
                  onClick={() => { setActiveTab('download'); setIsMenuOpen(false); }}
                  className={`block w-full text-left px-4 py-2 rounded-full ${activeTab === 'download' ? 'bg-yellow-500 text-white' : 'text-gray-800'}`}
                >
                  {t('appDownload')}
                </button>
                <button
                  onClick={() => { setActiveTab('intro'); setIsMenuOpen(false); }}
                  className={`block w-full text-left px-4 py-2 rounded-full mt-2 ${activeTab === 'intro' ? 'bg-yellow-500 text-white' : 'text-gray-800'}`}
                >
                  {t('productIntro')}
                </button>
              </div>
            )}
          </div>
        </nav>

        <div className="container mx-auto px-4 py-16 flex-grow">
          {activeTab === 'download' ? (
            <DownloadPage />
          ) : (
            <div className="max-w-4xl mx-auto overflow-auto">
              <IntroPage />
            </div>
          )}
        </div>

        <footer className="bg-yellow-300 py-6 mt-auto">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-center items-center md:space-x-6 text-gray-700 text-sm">
              <p className="mb-2 md:mb-0">Enum-X Co., Ltd.</p>
              <p className="mb-2 md:mb-0">
                <a href="mailto:support@enum-x.com"
                  className="hover:text-purple-600 transition-colors duration-300">
                  support@enum-x.com
                </a>
              </p>
              {/* 只在中文语言下显示 ICP 备案信息 */}
              {i18n.language.startsWith('zh') && (
                <p className="text-gray-600">
                  <a href="https://beian.miit.gov.cn/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-purple-600 transition-colors duration-300">
                    京ICP备2022026117号
                  </a>
                </p>
              )}
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
