import React, { useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import '../styles/Bubbles.css';  // 从 './Bubbles.css' 改为 '../styles/Bubbles.css'

// 导入所有语言的图片
const images = {
    en: {
        screen1: require('../assets/images/en/screen1.jpg'),
        screen2: require('../assets/images/en/screen2.jpg'),
        screen3: require('../assets/images/en/screen3.jpg'),
        screen4: require('../assets/images/en/screen4.jpg'),
    },
    zh: {
        screen1: require('../assets/images/zh/screen1.jpg'),
        screen2: require('../assets/images/zh/screen2.jpg'),
        screen3: require('../assets/images/zh/screen3.jpg'),
        screen4: require('../assets/images/zh/screen4.jpg'),
    },
    'zh-TW': {
        screen1: require('../assets/images/zh-TW/screen1.jpg'),
        screen2: require('../assets/images/zh-TW/screen2.jpg'),
        screen3: require('../assets/images/zh-TW/screen3.jpg'),
        screen4: require('../assets/images/zh-TW/screen4.jpg'),
    },
    ja: {
        screen1: require('../assets/images/ja/screen1.jpg'),
        screen2: require('../assets/images/ja/screen2.jpg'),
        screen3: require('../assets/images/ja/screen3.jpg'),
        screen4: require('../assets/images/ja/screen4.jpg'),
    },
    ko: {
        screen1: require('../assets/images/ko/screen1.jpg'),
        screen2: require('../assets/images/ko/screen2.jpg'),
        screen3: require('../assets/images/ko/screen3.jpg'),
        screen4: require('../assets/images/ko/screen4.jpg'),
    },
    fr: {
        screen1: require('../assets/images/fr/screen1.jpg'),
        screen2: require('../assets/images/fr/screen2.jpg'),
        screen3: require('../assets/images/fr/screen3.jpg'),
        screen4: require('../assets/images/fr/screen4.jpg'),
    },
};

// 修改气泡生成函数，根据索引生成不同的效果
const createBubbles = (container, index) => {
    // 为每个页面定义不同的颜色方案
    const colorSchemes = {
        0: [ // 第一个页面：粉色系
            'rgba(255, 192, 203, 0.4)',
            'rgba(255, 182, 193, 0.4)',
            'rgba(255, 218, 185, 0.4)',
        ],
        1: [ // 第二个页面：蓝色系
            'rgba(135, 206, 250, 0.4)',
            'rgba(176, 224, 230, 0.4)',
            'rgba(173, 216, 230, 0.4)',
        ],
        2: [ // 第三个页面：紫色系
            'rgba(216, 191, 216, 0.4)',
            'rgba(221, 160, 221, 0.4)',
            'rgba(238, 130, 238, 0.4)',
        ],
        3: [ // 第四个页面：黄色系
            'rgba(255, 255, 224, 0.4)',
            'rgba(255, 250, 205, 0.4)',
            'rgba(255, 228, 181, 0.4)',
        ]
    };

    // 为每个页面定义不同的气泡数量
    const bubbleCounts = {
        0: 25,  // 第一个页面较多气泡
        1: 15,  // 第二个页面适中
        2: 20,  // 第三个页面较多
        3: 18   // 第四个页面适中
    };

    // 为每个页面定义不同的大小范围
    const sizesRanges = {
        0: { min: 15, max: 40 },  // 第一个页面大小差异大
        1: { min: 20, max: 35 },  // 第二个页面中等大小
        2: { min: 10, max: 45 },  // 第三个页面更大的差异
        3: { min: 25, max: 50 }   // 第四个页面偏大
    };

    const colors = colorSchemes[index];
    const count = bubbleCounts[index];
    const { min, max } = sizesRanges[index];

    for (let i = 0; i < count; i++) {
        const bubble = document.createElement('div');
        bubble.className = 'bubble';

        // 根据页面设置不同的大小范围
        const size = Math.random() * (max - min) + min;
        bubble.style.width = `${size}px`;
        bubble.style.height = `${size}px`;

        // 根据页面设置不同的位置分布
        bubble.style.left = `${Math.random() * 100}%`;

        // 为每个页面设置不同的垂直分布范围
        const topRanges = {
            0: { start: 50, end: 100 },  // 第一个页面底部聚集
            1: { start: 40, end: 90 },   // 第二个页面分布较广
            2: { start: 45, end: 95 },   // 第三个页面中等分布
            3: { start: 35, end: 85 }    // 第四个页面靠上分布
        };
        const { start, end } = topRanges[index];
        bubble.style.top = `${Math.random() * (end - start) + start}%`;

        // 随机选择当前页面的颜色方案
        bubble.style.background = colors[Math.floor(Math.random() * colors.length)];

        // 随机旋转
        bubble.style.transform = `rotate(${Math.random() * 360}deg)`;

        container.appendChild(bubble);
    }
};

function IntroPage() {
    const { t, i18n } = useTranslation();
    const promoRefs = useRef([]);

    // 使用 useMemo 包装 languages 数组
    const languages = useMemo(() => [
        { code: 'en', label: 'English' },
        { code: 'zh', label: '简体中文' },
        { code: 'zh-TW', label: '繁體中文' },
        { code: 'ja', label: '日本語' },
        { code: 'ko', label: '한국어' },
        { code: 'fr', label: 'Français' },
    ], []);

    // 修改 screenshots 的实现
    const screenshots = useMemo(() => {
        const currentLang = i18n.language;
        const langImages = images[currentLang] || images.en;

        return [
            {
                img: langImages.screen1,
                title: t('screen1Title')
            },
            {
                img: langImages.screen2,
                title: t('screen2Title')
            },
            {
                img: langImages.screen3,
                title: t('screen3Title')
            },
            {
                img: langImages.screen4,
                title: t('screen4Title')
            },
        ];
    }, [i18n.language, t]);

    // 在组件加载时自动检测并设置默认语言
    useEffect(() => {
        const browserLang = navigator.language;
        // 处理带区域的语言代码，如 zh-CN, zh-TW
        const primaryLang = browserLang.split('-')[0];

        if (browserLang.startsWith('zh')) {
            // 特殊处理中文区域
            if (browserLang.includes('TW') || browserLang.includes('HK')) {
                i18n.changeLanguage('zh-TW');
            } else {
                i18n.changeLanguage('zh');
            }
        } else {
            // 检查是否支持该语言，如果不支持则默认使用英文
            const isSupported = languages.some(lang => lang.code === primaryLang);
            i18n.changeLanguage(isSupported ? primaryLang : 'en');
        }
    }, [i18n, languages]); // 添加依赖项

    // changeLanguage 函数简化
    const changeLanguage = (langCode) => {
        i18n.changeLanguage(langCode);
    };

    const downloadImage = (index) => {
        const element = promoRefs.current[index];
        if (!element) return;

        html2canvas(element, {
            useCORS: true,
            allowTaint: false,
            backgroundColor: null,
            scale: 12,  // 增加初始渲染的比例
            windowWidth: element.scrollWidth,
            windowHeight: element.scrollHeight,
            logging: false,
            imageTimeout: 0,
            removeContainer: true,
            // 添加更好的图像渲染设置
            imageSmoothingEnabled: false,
            imageSmoothingQuality: "high",
        }).then(canvas => {
            const resizedCanvas = document.createElement('canvas');
            resizedCanvas.width = 1320;
            resizedCanvas.height = 2868;
            const ctx = resizedCanvas.getContext('2d', {
                alpha: true,
                willReadFrequently: true,  // 优化性能
                imageSmoothingEnabled: true,  // 启用图像平滑
                imageSmoothingQuality: 'high'  // 使用最高质量的图像平滑
            });

            // 设置更好的图像渲染质量
            ctx.clearRect(0, 0, resizedCanvas.width, resizedCanvas.height);
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            // 绘制图像
            ctx.drawImage(canvas, 0, 0, 1320, 2868);

            // 使用更高质量的JPG导出设置
            const image = resizedCanvas.toDataURL("image/jpeg", 1.0);
            const link = document.createElement('a');
            link.href = image;
            link.download = `promo-${index + 1}.jpg`;
            link.click();
        }).catch(error => {
            console.error(t('downloadError'), error);
        });
    };

    // 修改 titleStyle
    const titleStyle = {
        fontFamily: "'Poppins', sans-serif",
        color: '#FF3366',  // 保持鲜艳的粉红色
        textShadow: `
            1.5px 1.5px 0 #4F46E5,  
            -1.5px -1.5px 0 #7C3AED,
            2px 2px 2px rgba(0,0,0,0.1)
        `,  // 增加错开距离，添加一层柔和的阴影
        display: 'inline-block',
        fontWeight: '800',
        letterSpacing: '0.5px',
        fontSize: '1.3rem'
    };

    return (
        <div className="py-16 px-4">
            <div className="mb-8 flex flex-wrap justify-center gap-2">
                {languages.map((lang) => (
                    <button
                        key={lang.code}
                        onClick={() => changeLanguage(lang.code)}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${i18n.language === lang.code
                            ? 'bg-purple-600 text-white'
                            : 'bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg hover:bg-opacity-30'
                            }`}
                    >
                        {lang.label}
                    </button>
                ))}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                {screenshots.map((screen, index) => (
                    <div key={index} className="relative">
                        <div className="rounded-lg p-2 bg-white shadow-lg">
                            <div
                                id={`promo-${index}`}
                                ref={el => promoRefs.current[index] = el}
                                className="shadow-lg rounded-lg"
                                style={{
                                    background: 'linear-gradient(135deg, #FEF9C3, #FEF08A, #FDE047)',
                                    backgroundSize: '200% 200%',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    aspectRatio: '1290 / 2796',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '30px',
                                    overflow: 'hidden',
                                }}
                            >
                                <div className="bubble-container" ref={el => {
                                    if (el && !el.hasChildNodes()) {
                                        createBubbles(el, index);  // 传入当前页面的索引
                                    }
                                }} />
                                <div className="text-center" style={{
                                    height: '80px',  // 固定高度
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 20px'
                                }}>
                                    <h3 className="font-bold" style={titleStyle}>
                                        {screen.title}
                                    </h3>
                                </div>
                                <div className="bg-black overflow-hidden shadow-inner rounded-[2.5rem]" style={{
                                    width: '95%',
                                    height: '85%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '6px',
                                    marginTop: '20px',  // 使用固定间距
                                }}>
                                    <img
                                        src={screen.img}
                                        alt={screen.title}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '2.2rem'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => downloadImage(index)}
                            className="mt-4 p-3 rounded-full bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg hover:bg-opacity-30 transition-all duration-300 shadow-lg"
                            aria-label={t('downloadPromo')}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default IntroPage;
