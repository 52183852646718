import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; // 确保这行存在
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet defaultTitle="贴密 - 树洞 | 漂流瓶 | 匿名社交 | 倾诉心声">
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#9333EA" />
      <link rel="canonical" href="https://cooktalk.top" />
    </Helmet>
    <App />
  </React.StrictMode>
);

reportWebVitals();
